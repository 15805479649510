/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State} from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
import { clearSessionStorageService } from '@/services/storage.service'
import  routing  from '@/route/routing';
const namespace: string = 'customer';


export default class ModelAccountMyAccountPayoutHistoryPage extends Vue {
	@State('customer') customer: CustomerState | undefined;

	logout() {
		clearSessionStorageService();
	}
}
